<template>
    <div class="columns pb-30" id="interested-in-block-container" v-if="withContainer && procurementClosing.length > 0">
        <div class="column">

            <general-container :customClass="customClass" :title="title">

                <template
                    v-if="procurementClosing && procurementClosing.length"
                    v-for="item in procurementClosing"
                    :key="item.contentId"
                >
                    <p>There are bids</p>
                </template>

                <p v-if="!loading && procurementClosing.length == 0" id="no-entries">Check back later for more relevant updates.</p>

            </general-container>

        </div>
    </div>

    <!-- Otherwise just use this block by itself (these are otherwise identical) -->
    <general-container :customClass="customClass" :title="title" v-else-if="procurementClosing.length > 0">

        <template
            v-if="procurementClosing && procurementClosing.length"
            v-for="item in procurementClosing.slice(0, 5)"
            :key="item.contentId"
        >
            <div class="col procurement-col">
                <procurement-introductory-card v-bind="item" :procurement="item"/>
            </div>
        </template>


        <read-more-link :contents="readMoreOverride ? readMoreOverride : readMore" :url="'/browse-procurements'"></read-more-link>

    </general-container>

    <!-- in the scenario there are no position changes on file -->
    <div class="general-container" v-else>
        <p class="general-container__title">{{ title }}</p>
        <div class="columns" id="interested-in-block-container">
            <div class="column">
                <general-container :customClass="'general-container--is-locked'">
                    <div class="no-data">
                        <img src="@/assets/svg/procurement.svg" alt="Procurement icon" class="no-data__icon" />
                        <p>We didn't find any procurements closing soon.</p>
                    </div>
                </general-container>
            </div>
        </div>
    </div>
</template>

<script>
import Preloader from "./preloader.vue";
import ReadMoreLink from "./read-more-link.vue";
import GeneralContainer from "./general-container.vue";
import InfoBlock from "./info-block.vue";
import InfoBlockStacked from "./info-block--stacked.vue";
import InfoBlockStackedBudgetUpdate from "./info-block--stacked-budget-update.vue";
import TagAgency from "./tag--agency.vue";
import TagRole from "./tag--role.vue";
import InfoBlockBudget from "./info-block--budget.vue";
import InfoBlockEmployment from "./info-block--employment.vue";
import InfoBlockTwitter from "./info-block--twitter.vue";
import {onMounted, ref} from "vue";
import RequestHandler from "@/handler/RequestHandler";
import ProcurementIntroductoryCard from "./procurement-introductory-card.vue";


export default {
    name: "procurements-news",
    components: {
        ProcurementIntroductoryCard,
        GeneralContainer, ReadMoreLink, Preloader,  InfoBlock,
        InfoBlockStacked, InfoBlockStackedBudgetUpdate,
        TagAgency, TagRole, InfoBlockBudget, InfoBlockEmployment,
        InfoBlockTwitter,
    },
    setup() {
        const sortFieldClosing = ref('end_date');
        const sortIsAscClosing = ref(true);
        const procurementClosing = ref([]);
        const procurementsSortedClosing  = ref([]);
        const procurementClosingLoading = ref(true);
        const loadProcurementsClosing = () => {
            procurementClosingLoading.value = true;


            RequestHandler.loadProcurementsClosing(
                sortFieldClosing.value,
                sortIsAscClosing.value,
            ).then(data => {
                procurementClosingLoading.value = false;
                procurementClosing.value = data.ContentList.list;
                setClosingProcurements();
            });
        };
        const setClosingProcurements = () => {
            let d = procurementClosing.value;

            function compare(a, b) {
                if (a[sortFieldClosing.value] < b[sortFieldClosing.value]) {
                    return sortIsAscClosing.value ? -1 : 1;
                }
                if (a[sortFieldClosing.value] > b[sortFieldClosing.value]) {
                    return sortIsAscClosing.value ? 1 : -1;
                }
                return 0;
            }
            procurementsSortedClosing.value = d.sort(compare);
        };

        onMounted(() => {
            loadProcurementsClosing();
        });

        return {
            loadProcurementsClosing,
            procurementClosing,
            procurementsSortedClosing,
        }
    },
    data() {
        return {
            customClass: "interested-in",
            readMore: "See More Procurement Updates",
            updates: [],
            loading: true,
        }
    },

}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/mixins/_bemify.scss";

.red {
    color: $red;
}

.green {
    color: $green--light;
}
.col {
    background-color: $white;
    border-radius: .25rem;
    padding: 0px;
    box-shadow: 0 0.5em 1em -0.125em rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02);
    margin-bottom: 15px;
    display: flex;
    .book-mark {
        display: block;
        //width: 20%;
        height: max-content;
    }
}

@include block('no-data') {
    font-weight: 600;
    font-family: $header;
    font-size: 1.25rem;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    @include element('title') {
        font-size: 1.5rem;
        margin-bottom: 5px;
    }
    @include element('icon') {
        font-size: 3.5rem;
        margin-bottom: -15px;
        max-width: 80px;
        margin-bottom: 15px;
    }
}
</style>
