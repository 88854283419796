<template>
    <general-container :customClass="'column'" :isLocked="false">

        <div class="login-container create-container" :class="{ 'logged-in-user': !isLoginPage }">
            <p class="warning-title">Explore more with a subscription!</p>
            <p class="mb0">What you see on this page is just the tip of the iceberg!</p>
            <p>Subscribing unlocks access to our exclusive tools, visuals, and much more.</p>
            <p>Experience the full potential of MyGovGuide with a subscription today!</p>

            <a v-if="!isLoginPage" @click="openModal" href="#" class="subscribe-button button is-primary mt15">Subscribe Now</a>
            <a v-else href="/register" class="subscribe-button button is-primary mt15">Create an Account to Subscribe</a>
        </div>

        <a href="/register" class="head padding">
            <h1> Budget History</h1>
        </a>
        <div className="container">

            <div class="org-chart-container padding">

                <p class="general-container__title has-text-centered">State of Florida Budget </p>

                <div class="container view-type-container" v-if="historical && historical.length">
                    <div class="toggle">

                        <span class="toggle__title">Switch View:</span>

                        <span id="view-toggle--table--one"
                            v-on:click="toggleViewBudget()" type="checkbox"
                            :checked="budgetGraphView"
                            class="view-toggle"
                        >
                                <img src="@/assets/svg/table--inactive.svg"
                                    alt="Show Graph View"
                                    v-if="!budgetGraphView"
                                    class="mr-10"
                                />
                                <img src="@/assets/svg/table--active.svg"
                                    alt="Show Table View"
                                    v-else
                                    class="mr-10"
                                />
                            </span>

                        <span id="view-toggle--card--one"
                            v-on:click="toggleViewBudget()" type="checkbox"
                            :checked="budgetGraphView"
                            class="view-toggle"
                        >
                                <img src="@/assets/svg/card--active.svg"
                                    alt="Show Graph View"
                                    v-if="!budgetGraphView"
                                />
                                <img src="@/assets/svg/card--inactive.svg"
                                    alt="Show Table View"
                                    v-else
                                />
                            </span>

                    </div>
                </div>
                <div class="container container--historical-chart" v-if="budgetGraphView && historical && historical.length">
                    <state-budget-chart :historical="historical"/>
                </div>

                <div class="container container--historical" v-if="(!budgetGraphView && historical && historical.length)">
                    <div class="columns is-block">
                        <div class="column">
                            <div class="tbl-scrollable responsive-table">
                                <table class="zebra">
                                    <thead>
                                    <tr>
                                        <th scope="col">Fiscal Year</th>
                                        <th scope="col">Spent</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="historicalBudget in historical">
                                        <td data-label="Fiscal Year">{{
                                                historicalBudget.fiscalYearFrom
                                            }} / {{ (historicalBudget.fiscalYearFrom + 1 + '').substr(2) }}
                                        </td>
                                        <td class="is-family-monospace has-text-right" data-label="Spent">
                                            ${{ historicalBudget.disbursementAmount < historicalBudget.appropriationAmount ? priceFormat(historicalBudget.disbursementAmount) : priceFormat(historicalBudget.appropriationAmount) }}
                                            ({{
                                                historicalBudget.disbursementAmount ?
                                                    historicalBudget.disbursementAmount < historicalBudget.appropriationAmount ?
                                                        (Math.round(
                                                            historicalBudget.disbursementAmount
                                                            / historicalBudget.appropriationAmount * 100
                                                        )) :
                                                        100
                                                    :
                                                    0
                                            }}%)
                                        </td>
                                        <td class="is-family-monospace has-text-right" data-label="Total">
                                            ${{ priceFormat(historicalBudget.appropriationAmount) }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mb-50">
            <general-container title="State of Florida Agency Organizational Chart" custom-class="org-chart-container">
                <org-tree-chart/>
            </general-container>
        </div>

        <div class="container">
            <div class="columns">
                <div class="column ">
                    <a href="/" class="head" >
                        <p :class="'header-title'">Bids Closing Soon</p>
                    </a>
                        <template
                            v-for="item in procurementClosing"
                            :key="item.contentId"
                        >
                            <div class="col procurement-col">
                                <div class="card__tools small view-undefined procurement-tools"></div>
                                <div class="block padd">
                                    <div class="column px-0 pt-0">
                                        <a href="/" class="head" >
                                            <p class="card__info__label card__info__label__with-tag">Bid</p>
                                        </a>
                                        <span >
                                            <tag-procurement :tag-text="item.title"/>
                                        </span>
                                    </div>

                                    <div class="columns mb-0">
                                        <div class="column pt-0" >
                                            <p class="card__info__label card__info__label__with-tag">Agency</p>
                                            <tag-agency :tag-text="item.agency.AgencyRef.name" />
                                        </div>
                                    </div>

                                    <div class="columns">
                                        <div class="column pt-0" >
                                            <p class="card__info__label mb-0">Ad Type</p>
                                            <p class="card__info__data">{{ item.adType }}</p>
                                        </div>
                                        <div class="column pt-0" >
                                            <p class="card__info__label mb-0">Agency Ad Number</p>
                                            <p class="card__info__data">{{ item.agencyAdNumber }}</p>
                                        </div>
                                    </div>

                                    <div class="columns">
                                        <div class="column pt-0" >
                                            <p class="card__info__label mb-0">Start Date</p>
                                            <p class="card__info__data">{{ item.startDateFormatted }}</p>
                                        </div>

                                        <div class="column pt-0" >
                                            <p class="card__info__label mb-0">End Date</p>
                                            <p class="card__info__data">{{ item.endDateFormatted }}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </template>
                </div>

                <div class="column">
                        <a href="/" class="head" >
                            <p :class="'header-title'">Top 5 Agencies by Budget Remaining</p>
                        </a>
                        <template
                            v-for="agency in agencies"
                            :key="agency.contentId"
                        >
                            <div class="info-block">

                                <div class="columns">
                                    <div class="column">
                                        <p class="heading mb-5">Agency</p>
                                        <tag-agency :tagText="agency.name"   />
                                    </div>
                                </div>

                                <p class="operating-budget-title has-text-centered">
                                    <Tooltip :tooltipText=" definitions['Operating Budget']">
                        <span class='has-tooltip'>
                            Operating Budget
                        </span>
                                    </Tooltip> - ${{ priceFormat(agency.budgetAppropriations) }}</p>

                                <div class="barWrapper">
                                    <div class="miniBar">
                                        <div :class="'miniBarProgress spent '" :style="'left: 0; width: ' + getPC(agency) + '%; background-color: gray;'" v-if="getPC(agency) > 0"><span>{{ getPC(agency) }}%</span></div>
                                        <div :class="'miniBarProgress remains ' + checkAmount()" :style="'left: ' + getPC(agency) + '%; width: ' + (100 - getPC(agency)) + '%; background-color: red;'"><span>{{ 100 - getPC(agency) + '%' }}</span></div>
                                    </div>
                                </div>

                                <nav class="level progress-level">
                                    <div class="level-item has-text-centered">
                                        <div>
                                            <p class="heading">Amount Spent</p>
                                            <p class="title">${{ priceFormat(agency.budgetDisbursements) + ' (' + getPC(agency) + '%)' }}</p>
                                        </div>
                                    </div>
                                    <div class="level-item has-text-centered">
                                        <div>
                                            <span class="slash">/</span>
                                        </div>
                                    </div>
                                    <div class="level-item has-text-centered">
                                        <div>
                                            <p class="heading">Budget Remaining</p>
                                            <p class="title">${{
                                                    priceFormat(agency.budgetAppropriations - agency.budgetDisbursements) + ' (' + (100 - getPC(agency)) + '%)'
                                                }}</p>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        <!--saved-contact-block v-if="hasBookmarks"></saved-contact-block-->
                    </template>
                </div>
            </div>
        </div>

        <div id="subscription-modal" :class="!isLoginPage && !user.subscription ? 'modal is-active' : 'modal'">
            <div class="modal-background" @click="closeSubscriptionModal()"></div>
            <div class="modal-card" :class="user && user.company && user.company.isAdmin ? 'company-card' : ''">
                <header class="modal-card-head">
                    <p class="modal-card-title">MyGovGuide Subscription</p>
                    <button class="delete" aria-label="close" @click="closeSubscriptionModal()"></button>
                </header>

                <section class="modal-card-body">
                    <!--               <p class="modal-card-subtitle">All subscriptions include a free 14-day trial period.</p> -->
                    <div>

                        <div>
                            <div class="card has-text-centered color-2">
                                <div class="card-content">
                                    <div class="content">
                                        <p class="title is-3 mb-0">Professional <br /> Subscription</p>
                                        <p class="extra">Annual License</p>
                                        <p class="subtitle is-4 price">$5,400</p>
                                        <p class="subtitle is-5 desc">per license/year</p>
                                        <ul>
                                            <li>Full Access Account</li>
                                            <li>1 user license</li>
                                            <li>Search</li>
                                            <li>Track</li>
                                            <li>Tag</li>
                                            <li>Record</li>
                                        </ul>
                                        <p class="extra">Enterprise Licenses Available</p>
                                    </div>

                                    <footer class="card-footer">

                                        <a href="#" @click="openCheckout('annual')" class="card-footer-item">
                                            Subscribe to MyGovGuide Professional Annually
                                        </a>
                                    </footer>
                                </div>
                            </div>
                        </div>
                        <p class="sub-detail">
                            If you are a state government organization or employee, please email us at
                            <a href="mailto:contact@mygovguide.com">
                                contact@mygovguide.com
                            </a>
                            to request a quote for discounted government pricing.
                        </p>
                    </div>
                </section>

            </div>
        </div>

         <div id="force-reset-password-modal" :class="user.forcePasswordReset ? 'modal is-active' : 'modal'">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Set Your Password</p>
                </header>

                <section class="modal-card-body">
                    <div class="columns">

                        <div class="column">
                            <div class="card has-text-centered color-2">
                                <div class="card-content">
                                    <div class="content">
                                        <div class="field">
                                            <label class="label" for="p-new-password">New Password</label>
                                            <div class="control">
                                                <input class="input" type="password" id="p-new-password"
                                                    required
                                                    v-model="passwordParams.newPassword">
                                            </div>
                                            <PasswordRequirements :password="passwordParams.newPassword"/>
                                        </div>

                                        <div class="field">
                                            <label class="label" for="p-confirm-password">Confirm Password</label>
                                            <div class="control">
                                                <input class="input" type="password" id="p-confirm-password"
                                                    v-model="passwordParams.confirmPassword">
                                            </div>
                                        </div>

                                        <div v-if="passwordError" v-html="passwordError" class="error-message"></div>
                                    </div>

                                    <footer class="card-footer">
                                       <div>
                                            <button class="button"
                                                    @click.prevent="savePassword()"
                                                    :disabled="pendingChangePassword ? '' : null"
                                            >
                                                Set password
                                            </button>
                                        </div>
                                    </footer>
                                    <div class="sub-detail">
                                        <a href="/logout" class="" @click.prevent="logoutClick">Logout</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    </general-container>
</template>



<script >
    import {computed, watchEffect, defineComponent, onMounted, ref, reactive} from "vue";
    import { useRouter } from "vue-router";
    import RequestHandler from "@/handler/RequestHandler";
    import useUser from "@/helpers/useUser";
    import { ACTION_TYPES, MUTATION_TYPES } from "@/constants";
    import AgencyBudgetBlock from "./agency-budget-block.vue";
    import StateBudgetChart from "./state-budget-chart.vue";
    import GeneralContainer from "./general-container.vue";
    import ProcurementsNews from "./procurements-news.vue";
    import TagAgency from "./tag--agency.vue";
    import { priceFormat } from "@/helpers/filter";
    import {useStore} from "vuex";
    import CardTools from "./card-tools.vue";
    import ProcurementFlyout from "./procurement-flyout.vue";
    import TagProcurement from "./tag--procurement.vue";
    import OrgTreeChart from "./org-tree-chart";
    import ProcurementIntroductoryCard from "./procurement-introductory-card.vue";

    export default {
        name: 'teaser-page',
        components: {
            ProcurementIntroductoryCard,
            TagProcurement,
            ProcurementFlyout,
            CardTools,
            OrgTreeChart,
            TagAgency,
            ProcurementsNews,
            GeneralContainer,
            StateBudgetChart,
            AgencyBudgetBlock
        },
        methods: {
            priceFormat() {
                return priceFormat
            },

            toggleViewBudget() {
                this.budgetGraphView = !this.budgetGraphView;
            },

            getPC(agency) {
                if ((agency.budgetDisbursements / agency.budgetAppropriations) > 1) {
                    return 100;
                } else {
                    return Math.round(agency.budgetDisbursements / agency.budgetAppropriations * 100);
                }
            },

            checkAmount(agency) {
                if (!agency) {
                    return '';
                }
                let amount = Math.round(agency.budgetDisbursements / agency.budgetAppropriations * 100);
                if (amount == 100) {
                    return ' full';
                } else if (amount == 0) {
                    return ' empty';
                }
            },

            closeSubscriptionModal: function() {
                const el = document.getElementById('subscription-modal')
                if (el) {
                    el.classList.remove('is-active');
                }
            },

            closeForcePasswordResetModal: function() {
                const el = document.getElementById('force-reset-password-modal')
                if (el) {
                    el.classList.remove('is-active');
                }
            },

            openModal: function() {
                const modal = document.getElementById('subscription-modal')
                modal.classList.add('is-active');
            },

        },
        setup() {
            const router = useRouter();
            const {redirectIfNotLoggedIn, userLoaded, original_user, logout} = useUser();

            let user = original_user ? original_user : {subscription:false};

            watchEffect(() => {
                // redirectIfNotLoggedIn(router);
            });

            const urlPath = window.location.pathname;
            let isLoginPage = false;
            if (urlPath === '/login') {
                isLoginPage = true;
            };

            const store = useStore();
            const definitions = computed(() => store.getters.definitions);
            const historical = ref([]);
            const procurementClosing = ref([]);
            const agencyId = ref('');
            const cbPlanOption = ref('');
            const dispayDeniedMessage = ref(false);
            const agencies =ref([]);
            const budgetGraphView = ref(true);
            const sortFieldClosing = ref('end_date');
            const cbLoaded = ref(false);

            const passwordParams = reactive({
                oldPassword: store.getters.savedPasswordFromData,
                newPassword: null,
                confirmPassword: null,
            });
            const pendingChangePassword = ref(false);
            const passwordError = ref('');
            const savePassword = () => {
                RequestHandler.updatePassword(passwordParams, store.getters.csrf)
                    .then(() => {
                        passwordParams.newPassword = null;
                        passwordParams.confirmPassword = null;
                        passwordError.value = '';
                        reloadUser();
                        closeForcePasswordResetModal();
                    })
                    .catch((error) => {
                        passwordError.value = (error.response.data && error.response.data.error)
                            ? error.response.data.error.replaceAll('ERROR:', '<br>●')
                            : 'Something went wrong, please try again';
                    });
            };
            const logoutClick = () => {
                logout().then(() => router.push({'name': 'login'}));
            };

            let cbInstance = null;

            const reloadUser = () => {
                store.dispatch(ACTION_TYPES.loadUser);
                setTimeout(() => {
                    store.dispatch('fetchAgencies');
                    store.dispatch('loadAllDefinitions');
                }, 2000);
            };

            const loadHistoricalBudgets = () => {
                historical.value = [];
                RequestHandler.loadHistoricalBudgets(agencyId.value).then(response => {
                    historical.value = response;
                });
            }
            const loadProcurementsClosing = () => {
                RequestHandler.loadProcurementsClosing(
                    sortFieldClosing.value,
                    // sortIsAscClosing.value,
                ).then(data => {
                    procurementClosing.value = data.ContentList.list;
                });
            };
            const loadTopAgencies = () => {
                RequestHandler.loadTopAgencies().then(ContentList => {
                    agencies.value = ContentList.list;
                }).catch((error) => {
                    console.error(error);
                });
            }
            const openCheckout = (planOption) => {
                cbPlanOption.value = planOption;
                loadCheckout();
            };

            const initChargebee = () => {
                if (typeof Chargebee === undefined) {
                    setTimeout(() => initChargebee(), 1000);
                    return;
                }
                RequestHandler.loadChargebeeConfig().then(({ site }) => {
                    Chargebee.init({ site });
                    cbInstance = Chargebee.getInstance();
                    cbLoaded.value = true;
                });
            };

            const loadCheckout = () => {
                dispayDeniedMessage.value = false;
                cbInstance.openCheckout({
                    hostedPage: () => RequestHandler.loadChargebeeCheckout(cbPlanOption.value),
                    success: reloadUser,
                    close: reloadUser,
                });
            }

            const openPortal = () => {
                dispayDeniedMessage.value = false;
                const cbPortal = cbInstance.createChargebeePortal();
                cbInstance.setPortalSession(() => RequestHandler.loadChargebeePortalSession());
                cbPortal.open({
                    close: reloadUser,
                    subscriptionChanged: reloadUser,
                    subscriptionCancelled: reloadUser,
                    subscriptionPaused: reloadUser,
                    subscriptionResumed: reloadUser,
                    subscriptionReactivated: reloadUser
                });
            };

            onMounted( ()=> {
                initChargebee();
                loadHistoricalBudgets();
                loadProcurementsClosing();
                loadTopAgencies();
            })

            return {
                user,
                historical,
                budgetGraphView,
                priceFormat,
                definitions,
                procurementClosing,
                sortFieldClosing,
                loadProcurementsClosing,
                agencies,
                loadTopAgencies,
                openCheckout,
                cbPlanOption,
                loadCheckout,
                dispayDeniedMessage,
                openPortal,
                cbLoaded,
                savePassword,
                passwordParams,
                passwordError,
                logoutClick,
                isLoginPage
            };
        }
    }

</script>


<style scoped lang="scss">
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/helpers/responsive-tables";
    .head {
        text-align: center;
        text-decoration: none;
    }
    .padd {
        padding: 15px;
    }
    .padding {
        padding: 0 15px;
        margin-bottom: 15px;
    }
    .header-title {
        font-weight: 600;
        font-family: Fira Sans,sans-serif;
        font-size: 28px;
        margin-bottom: .5em;
        color: #022c43;
        &.has-a-tooltip {
            border-bottom: 1px dashed $blue--med;
        }
    }
    .col{
        background-color: #fff;
        border-radius: 0.25rem;
        padding: 0;
        box-shadow: 0 0.5em 1em -0.125em rgba(0,0,0,.1), 0 0 0 1px rgba(0,0,0,.02);
        margin-bottom: 15px;
        display: flex;
    }
    .card__info__label {
        label {
            font-size: 28px;
            font-weight: 600;
            font-family: $header;
            text-transform: capitalize;
            margin-bottom: 5px;
            @media screen and (max-width: 768px) {
                width: 100%;
                display: block;
            }
        }
    }

    #subscription-modal, #subscription-modal--trial-expired, #trial-modal, #tos-modal {
        .modal-card-title {
            margin-bottom: 0px;
        }

        .card {
            .title, .subtitle {
                color: $white;
                &.price {
                    margin-bottom: none;
                }
                &.desc {
                    border-bottom: 1px solid #fff;
                    padding-bottom: 20px;
                }
            }
            .content {
                ul {
                    list-style-type: none !important;
                    margin-left: 0px;
                }
            }
            .card-footer {
                border-top: none;
                .card-footer-item {
                    text-decoration: none;
                    border-radius: 6px;
                    &:hover, &:focus {
                        text-decoration: underline;
                    }
                }
            }
            &.color-1 {
                background-color: $blue--med;
                color: $white;
                .card-footer .card-footer-item {
                    background-color: $blue--dark;
                    color: $white;
                }
            }
            &.color-2 {
                background-color: $blue--dark;
                color: $white;
                .card-footer .card-footer-item {
                    background-color: $blue--med;
                    color: $white;
                }
            }
        }

        .company-card {
            width: 960px;
            @media screen and (max-width: 1021px) {
                width: 100%;
            }
        }

        .sub-detail {
            margin: 10px 15px;
        }
    }

    .login-container {
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 30px;
        text-align: center;
        max-width: 650px;
        @media screen and (max-width: 650px) {
            margin: 0 15px;
        }
    }
    .create-container {
        background-color: #e1f7ea;
        border: 1px solid $emerald;
        padding: 15px;
        @media screen and (max-width: 650px) {
            margin-top: 50px;
        }
        &.logged-in-user {
            background-color: #FFEFF0;
            border: 1px solid #ed5353;
            .warning-title {
                color: $rosso-corsa;
            }
        }
    }
    .warning-title {
        color: $pine-green;
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 0px;
    }
    .subscribe-button {
        text-decoration: none;
        &:hover, &:focus {
            text-decoration: underline;
        }
    }

    .mb0 {
        margin-bottom: 0px;
    }
    .mt10 {
        margin-top: 10px;
    }

    #force-reset-password-modal {
        footer div {
            width: 100%;
            margin-bottom: 20px;
            margin-top: 20px;
        }
    }
</style>

