<template>

    <card-tools
        :procurement="procurement"
        customClass="procurement-tools"
        size="small"
    />

    <div :class="'snackbar-procurement-pair snackbar-procurement-pair_' + rand">
        <div class="snackbar-container">
            <div @click="hideFlyout(rand)" class="close-item"></div>
            <procurement-flyout
                v-if="procurementContentId"
                v-bind="procurementProps"
                :agency="procurementProps.agency && procurementProps.agency.AgencyRef ? procurementProps.agency.AgencyRef : null"
            ></procurement-flyout>
        </div>
    </div>

    <div class="block">
        <a class="column px-0 pt-0">
            <p class="card__info__label card__info__label__with-tag">Procurement</p>
            <span @click="openFlyout(locationId, rand)">
                <tag-procurement :tag-text="title"/>
            </span>
        </a>

        <div class="columns mb-0">
            <div class="column pt-0" v-if="agency">
                <p class="card__info__label card__info__label__with-tag">Agency</p>
                <tag-agency :tag-text="agency.AgencyRef.name" :tag-url="agency.AgencyRef.url"/>
            </div>
        </div>

        <div class="columns">
            <div class="column pt-0" v-if="adType">
                <p class="card__info__label mb-0">Ad Type</p>
                <p class="card__info__data">{{ adType }}</p>
            </div>
            <div class="column pt-0" v-if="agencyAdNumber">
                <p class="card__info__label mb-0">Agency Ad Number</p>
                <p class="card__info__data">{{ agencyAdNumber }}</p>
            </div>
        </div>

        <div class="columns">
            <div class="column pt-0" v-if="startDate">
                <p class="card__info__label mb-0">Start Date</p>
                <p class="card__info__data">{{ startDateFormatted }}</p>
            </div>

            <div class="column pt-0" v-if="endDate">
                <p class="card__info__label mb-0">End Date</p>
                <p class="card__info__data">{{ endDateFormatted }}</p>
            </div>
        </div>

    </div>
</template>

<script>
import TagAgency from "./tag--agency.vue";
import TagProcurement from "./tag--procurement.vue";
import IframeModal from "./iframe-modal.vue";
import ProcurementFlyout from "./procurement-flyout.vue";
import RequestHandler from "@/handler/RequestHandler";
import {computed, ref} from "vue";
import {useStore} from "vuex";
import CardTools from "./card-tools.vue";


export default {
    name: "procurement-introductory-card",
    components: {CardTools, ProcurementFlyout, IframeModal, TagAgency, TagProcurement},
    props: {
        contentId: Number,
        locationId: Number,
        title: String,
        status: String,
        adType: String,
        startDate: String,
        endDate: String,
        startDateFormatted: String,
        endDateFormatted: String,
        agencyAdNumber: String,
        agency: Object,
        id: String,
        description: String,
        procurement: Object
    },
    data() {
        return {
            procurementContentId: null,
            procurementProps: null,
        }
    },
    methods: {
        openFlyout(locationId, id) {
            RequestHandler.loadProcurement(locationId).then((data) => {
                if (data && data.ProcurementRef) {
                    this.procurementContentId = data.ProcurementRef.contentId;
                    this.procurementProps = data.ProcurementRef;
                    var x = document.getElementsByClassName("snackbar-procurement-pair_" + id)[0];
                    x.classList.add('show');
                    x.classList.add('fadein');
                    document.body.classList.add('position-fixed');
                }
            }).catch(e => {
                console.log(e);
            })
        },
        hideFlyout: function(id) {
            var x = document.getElementsByClassName("snackbar-procurement-pair_" + id)[0];
            x.classList.remove('fadein');
            x.classList.add('fadeout');
            document.body.classList.remove('position-fixed');
            setTimeout(function(){
                x.classList.remove('show');
                x.classList.remove('fadeout');
            }, 500);
        },
        titleize: function(item) {
            return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
        },
    },
    setup(props) {
        const showIframe = ref(false);
        const store = useStore();
        const definitions = computed(() => store.getters.definitions);

        const rand = Math.floor(100000 + Math.random() * 900000);

        const newIframeCreated = () => {
            showIframe.value = false;
        };

        return {
            newIframeCreated,
            showIframe,
            definitions,
            rand
        };
    },
    mounted: function () {

    },
}
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .column .card__info__data {
        margin: 0!important;
    }

    @include block('card') {
        .media {
            display: contents;
        }
        @include element('info') {
            @include element('label') {
                float: left;
                margin-right: 10px;
                display: block;
                width: 100%;
                margin-bottom: 5px !important;
                &.top {
                    margin-bottom: 0px !important;
                }
            }
            @include element('description') {
                line-height: 1.7em;
            }
        }
    }

    .level {
        justify-content: start;
        display: flow-root;
        .level-item {
            display: inline-flex !important;
            max-width: 200px;
            width: auto;
            margin-right: 40px;
            .title {
                font-size: 1.25rem;
            }
            .end-date {
                max-width: 250px;
            }
        }
    }

    .card__info__label.card__info__label__link {
        font-size: 15px;
    }
    .card__info__label, .card__info__label.card__info__label__with-tag {
        color: $grey--dark !important;
    }

    .close-item {
        position: absolute;
        right: 20px;
        z-index: 1;
    }

    .block {
        padding: 15px;
        width: calc(100% - 40px);
    }

    .px-0 {
        padding-left: 0px;
        padding-right: 0px;
    }
    .pt-0 {
        padding-top: 0px;
    }
    .mb-0 {
        margin-bottom: 0px;
    }
    .card__info__label.mb-0 {
        margin-bottom: 0px !important;
    }

    // see partial for .snackbar-procurement-pair
</style>
