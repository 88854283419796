<template>
    <!-- Component: organisms/org-tree-chart -->
    <organization-chart :tree="orgTree" v-if="!loading" :isStateOrgChart="true"/>
    <div class="legend">
        <div class="legend-executive">
            <div class="icon"></div>
            <span>Executive</span>
        </div>
        <div class="legend-cabinet">
            <div class="icon"></div>
            <span>Cabinet</span>
        </div>
        <div class="legend-legislative">
            <div class="icon"></div>
            <span>Legislative</span>
        </div>
        <div class="legend-position">
            <div class="icon"></div>
            <span>Position</span>
        </div>
        <div class="legend-entity">
            <div class="icon"></div>
            <span>Entity</span>
        </div>
    </div>
    <preloader v-if="loading"/>
    <!-- Component: organisms/org-tree-chart -->
</template>

<script>
    import { onMounted, ref, toRefs, watch } from "vue";
    import RequestHandler from "@/handler/RequestHandler";
    import Preloader from "./preloader";
    import OrganizationChart from "./organization-chart";

    export default {
        name: 'OrgTreeChart',
        components: {Preloader, OrganizationChart},
        props: [],
        setup(props) {
            const loading = ref(true);
            const orgTree = ref([]);

            onMounted(() => {
                RequestHandler.loadOrgChartTree().then(tree => {
                    loading.value = false;
                    orgTree.value = tree;
                }).catch(error => {
                    loading.value = false;
                    console.error(error);
                });
            });

            return {loading, orgTree,};
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";

    .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    .legend {
        padding-left: 20px;
        padding-top: 25px;
        > div > span {
            position: relative;
            top: -3px;
        }
    }

    .legend-executive {
        .icon {
            background-color: rgba(51, 116, 170, 0.075);
            border: 1px solid #3374aa;
        }
    }
    .legend-cabinet {
        .icon {
            background-color: #effaf5;
            border: 1px solid #257953;
        }
    }
    .legend-legislative {
        .icon {
            background-color: #fffaeb;
            border: 1px solid #946c00;
        }
    }
    .legend-position {
        .icon {
            background-color: #feecf0;
            border: 1px solid #cc0f35;
        }
    }
    .legend-entity {
        .icon {
            background-color: #f2ecfe;
            border: 1px solid #3b0fcc;
        }
    }
</style>
